import { Routes, Route, Link } from "react-router-dom";
import Home from "./routes/home";
import Login from "./routes/login";
import Resgister from "./routes/resgister";
import Thanks from "./routes/thanks";

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Resgister />} />
        <Route path="/thanks" element={<Thanks />} />
      </Routes>
    </div>
  );
}
