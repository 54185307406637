import { useNavigate } from "react-router-dom";

export default function Thanks() {
  const navigate = useNavigate();
  return (
    <div className="thanks">
      <span className="emoji">✅</span>
      <h2>Product registered successfully</h2>
      <div
        onClick={() => {
          navigate("/");
        }}
      >
        Click here to register another QR code{" "}
      </div>
    </div>
  );
}
